import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-752a3348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-header" }
const _hoisted_2 = { class: "view-container d-mx-auto" }
const _hoisted_3 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _withDirectives(_createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createTextVNode("导航首页")
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, !_ctx.isHomePage]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.name
          }, [
            _createVNode(_component_router_link, {
              to: item.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ])
  ]))
}