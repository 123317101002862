import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f20b7070"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "view-container d-mx-auto" }
const _hoisted_3 = { class: "view-container d-mx-auto d-padding-top-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_JuHeSearch = _resolveComponent("JuHeSearch")!
  const _component_NavItems = _resolveComponent("NavItems")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_JuHeSearch)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_NavItems)
    ])
  ]))
}